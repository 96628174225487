import React, { useState } from "react";
import emailjs from 'emailjs-com';
import { BsCheckCircle } from "react-icons/bs";
import Modal from "react-modal";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import "./ContactForm.css";
import { ErrorMessage, ExtraLargeTitle, Text } from "../../../components/shared/FontCollection";

function TextInput(props) {
    return <input type="text" {...props} />
}

function TextArea(props) {
    return <textarea rows={6} {...props} />
}

const Inputs = {
    name: TextInput,
    email: TextInput,
    message: TextArea
}

function InputWrapper({ inputId, label, placeholder, formik }) {
    let Input = Inputs[inputId];

    return <div className="contact-form__row">
        <div className="contact-form__label">{label} *</div>
        <div className="contact-form__input">
            <Input
                id={inputId}
                name={inputId}
                className={formik.touched[inputId] && formik.errors[inputId] ? "contact-form__input--error" : ""}
                placeholder={placeholder.replace("{0}", label.toLowerCase())}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[inputId]} />
        </div>
        <div className="contact-form__error">
            {
                formik.touched[inputId]
                && formik.errors[inputId]
                && <ErrorMessage>{formik.errors[inputId]}</ErrorMessage>
            }
        </div>
    </div>
}

export default function ContactForm({ translationData }) {
    const [isOpen, setIsOpen] = useState(false);
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: ""
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .max(50, translationData.contact__validation__nameLength)
                .required(translationData.contact__validation__nameRequired),
            email: Yup
                .string()
                .email(translationData.contact__validation__emailFormat)
                .required(translationData.contact__validation__emailRequired),
            message: Yup.string()
                .required(translationData.contact__validation__messageRequired)
        }),
        onSubmit: (values, { resetForm }) => {
            const data = {
                visitor_name: values.name,
                visitor_email: values.email,
                visitor_message: values.message
            };

            emailjs
                .send(process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                    data,
                    process.env.REACT_APP_EMAILJS_USER_ID)
                .then(() => {
                    setIsOpen(true);
                    resetForm();
                });
        }
    });

    function onSubmit(e) {
        e.preventDefault();
        formik.handleSubmit(e);
    }

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return <section className="contact-form">
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog">
            <div className="notify-message">
                <div className="notify-message__icon">
                    <BsCheckCircle size="32" color="green" />
                </div>
                <div className="notify-message_text">
                    <Text>{translationData.contact__popup__message}</Text>
                </div>
            </div>
            <div onClick={toggleModal} className="notify-message__close-button"><Text>{translationData.contact__popup__closeButton} </Text></div>
        </Modal>

        <div className="contact-form__content">
            <div className="contact-form__title">
                <ExtraLargeTitle>{translationData.contact__title} </ExtraLargeTitle>
            </div>
            <form onSubmit={onSubmit}>
                <InputWrapper inputId="name" placeholder={translationData.contact__input__placeholder} label={translationData.contact__name} formik={formik} />
                <InputWrapper inputId="email" placeholder={translationData.contact__input__placeholder} label={translationData.contact__email} formik={formik} />
                <InputWrapper inputId="message" placeholder={translationData.contact__input__placeholder} label={translationData.contact__message} formik={formik} />
                <div className="contact-form__row">
                    <input type="submit" value={translationData.contact__submitButton} />
                </div>
            </form>
            <div></div>
        </div>
    </section>
}