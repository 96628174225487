import React from "react";

import { useLanguageContext } from "../../components/shell/LanguageContext";
import { normalizeTranslations } from "../../services/translationHelper.service";
import ContactForm from "./sections/ContactForm";

export default function ContactContent({ translationData }) {
    const languageData = useLanguageContext();
    const normalizedTranslationData = normalizeTranslations(languageData.language, translationData);

    return <React.Fragment>
        <ContactForm translationData={normalizedTranslationData} />
    </React.Fragment>
}