import * as React from "react"
import { graphql } from "gatsby";

import Seo from "../components/shell/seo"
import LayoutWrapper from "../template/LayoutWrapper";
import ContactContent from "../pageContents/contact/ContactContent";
import { getSeoData } from "../services/seo.service";

const seoData = {
  "seoTranslations": [
    {
      "language": "jp",
      "title": "MORIMAのお問合せ",
      "keywords": "お問合せ",
      "description": "会社名:株式会社ＭＯＲＩＭＡ - 住所: 東京都豊島区東池袋3丁目５-7 ユニオンビル8F - 電話:+81 70 4125 2847 - メール:info@morima.co.jp"
    },
    {
      "language": "en",
      "title": "MORIMA's contact information",
      "keywords": "Contact",
      "description": "Company name:MORIMA Co., Ltd. - Address:Union Building 8F, 3-5-7 Higashiikebukuro, Toshima-ku, Tokyo - Phone:+81 70 4125 2847 - Email:info@morima.co.jp"
    }
  ]
};

function ContactPage(props) {
  return <LayoutWrapper pageContext={props.pageContext}>
    <Seo {...getSeoData(props, seoData)} />
    <ContactContent translationData={props.data.contactJson} />
  </LayoutWrapper>
}

export default ContactPage;

export const query = graphql`
  {
    contactJson {
      translations {
        contact__email
        contact__input__placeholder
        contact__message
        contact__name
        contact__popup__closeButton
        contact__popup__message
        contact__submitButton
        contact__submit_button
        contact__title
        contact__validation__emailFormat
        contact__validation__emailRequired
        contact__validation__messageRequired
        contact__validation__nameLength
        contact__validation__nameRequired
        language
      }
    }
  }
`